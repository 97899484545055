import { useEffect } from "react";
import {
  useLocation, matchPath, useSearchParams, useNavigate,
} from "react-router-dom";


// Invisible: only causes navigation effects
function MaybeRedirect(props) {
  const [searchParams,] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoaded, loggedIn, singlePage } = props;
  const { pathname: urlPath } = location;
  const paramString = searchParams.toString();

  useEffect(() => {
    let ignore = false;

    if (isLoaded === false) {
      console.debug("first load: doing nothing");
      return;
    }

    const atRoot = matchPath("/", urlPath) !== null;
    const atLogin = matchPath("/login/*", urlPath) !== null;
    const atSignUp = matchPath("/sign-up/*", urlPath) !== null;
    const atFulfillmentPolicy = matchPath(
      "/fulfillment-policy/*", urlPath
    ) !== null;

    console.debug(`at root? ${atRoot}`);
    console.debug(`at /login? ${atLogin}`);
    console.debug(`at /sign-up? ${atSignUp}`);

    // In single-page mode, / (root) is the only location that matters
    if (!atRoot && singlePage) {
      console.debug("single-page: redirecting to root");
      if (!ignore) {
        navigate(`/?${paramString}`);
      }
      return;
    }

    // Normally, if already logged in, redirect to account settings (eventually,
    // the landing page for the user)
    if (atRoot && !singlePage && loggedIn) {
      console.debug("not single-page: redirecting to landing");
      if (!ignore) {
        navigate(`/landing/?${paramString}`);
      }
      return;
    }

    // If not logged in, ensure we're at /, /login, /sign-up, /fulfillment-policy
    if (!loggedIn && !atLogin && !atRoot && !atSignUp && !atFulfillmentPolicy) {
      console.debug("not logged in: redirecting to home page");
      if (!ignore) {
        navigate(`/?${paramString}`);
      }
      return;
    }

    // Else, no redirects necessary
    console.debug("page ok: not redirecting");

    return () => {
      ignore = true;
    };
  }, [isLoaded, urlPath, loggedIn, navigate, paramString, singlePage]);

  return <></>;
}


export { MaybeRedirect };