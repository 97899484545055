import { loadStripe } from "@stripe/stripe-js";

if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
  throw new Error("must set stripe public key");
}

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const StripePromise = loadStripe(stripePublicKey);


export { StripePromise };