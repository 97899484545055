import { Route, Routes } from "react-router-dom";


function FulfillmentPolicy() {
  return (
    <>
      <Routes>
        <Route path="fulfillment-policy" element={<FulfillmentPolicyText />} />
      </Routes>
    </>
  );
}


function FulfillmentPolicyText() {
  return (
    <>
      <h2>Fulfillment Policy</h2>
      <p>
        Refund policy: no payments or fees will be refunded, unless the customer
        is not admitted to the program.
      </p>
      <p>
        Shipping or delivery policy: EE/EEI does not ship any physical goods.
      </p>
      <p>
        Return policy and process: there are no returns or refunds for the
        service.
      </p>
      <p>
        Cancellation policy: the contract can be cancelled by the student or
        their guardians at any time. In doing so, they will forfeit the services
        for the remainder of the contract period, but will receive no refund,
        spartial or complete.
      </p>
    </>
  );
}


export { FulfillmentPolicy };