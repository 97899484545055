import { FirebaseError } from "firebase/app";
import { httpsCallable } from "firebase/functions";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import { Alert } from "./Alert";
import { Functions } from "./FirebaseConfig";
import { Checkbox, EditableTextField, SingleSelectField } from "./Forms";
import { LoginContext } from "./LoginContext";
import { StripePromise } from "./Stripe";


const createStripeSession = httpsCallable(Functions(), "createstripesession");
const finishStripeSession = httpsCallable(Functions(), "finishstripesession");


function SignUp(props) {
  const { adminOnly } = props;
  const loginContext = useContext(LoginContext);
  const [clientSecret, setClientSecret] = useState("");

  if (adminOnly && !(loginContext?.loggedIn && loginContext?.claims?.admin)) {
    return <></>;
  }

  return (
    <>
      <Routes>
        <Route path="sign-up/*">
          <Route path="return" element={<SignUpReturn />} />
          <Route
            path="payment"
            element={<SignUpPayment clientSecret={clientSecret} />}
          />
          <Route
            index={true}
            element={<SignUpForm setClientSecret={setClientSecret} />}
          />
        </Route>
      </Routes>
    </>
  );
}

function SignUpPayment(props) {
  const { clientSecret } = props;
  const stripeOptions = { clientSecret: clientSecret };

  if (!clientSecret) {
    throw new Error("invalid page");
  }

  return (
    <>
      <h2>And the Money</h2>
      <p>
        You knew this was coming. We need money, mostly to bribe admissions
        officers, but we also allocate some to pay our small staff.
      </p>
      <EmbeddedCheckoutProvider stripe={StripePromise} options={stripeOptions}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </>
  );
}

function SignUpReturn() {
  const [searchParams,] = useSearchParams();
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const [sessionResult, setSessionResult] = useState(null);
  const paymentIdParam = searchParams.get("payment_id") || "";
  const paymentIdRef = useRef(paymentIdParam);
  const { current: paymentId } = paymentIdRef;
  const haveResult = !!sessionResult;

  useEffect(() => {
    let ignore = haveResult || !paymentId;

    if (ignore) {
      return;
    }

    finishStripeSession({ paymentId: paymentId })
      .then(result => {
        setSessionResult(data => {
          // only accept the first response we get
          return data || result.data;
        });
      })
      .catch(err => {
        console.debug(err);
        if (ignore) {
          return;
        }
        if (err instanceof FirebaseError) {
          console.debug(`code: ${err.code}`);
          if ("functions/already-exists" === err.code) {
            console.warn("payment is already processing; assuming duplicate");
            return;
          }
        }
        setErrorText(`error from payment system: ${err}`);
      });
    return () => {
      ignore = true;
    };
  }, [paymentId, haveResult]);

  console.debug(paymentId);

  if (!paymentId) {
    return (
      <>
        <Alert
          text="invalid page: no payment id provided"
          timeout={5000}
          disabled={false}
          onExpired={() => {
            navigate("/");
          }}
          isError={true} />
      </>
    );
  }

  if (errorText) {
    return (
      <>
        <Alert
          text={errorText}
          timeout={10000}
          disabled={false}
          onExpired={() => {
            setErrorText("");
            setSessionResult(null);
            navigate(`/?${searchParams.toString()}`);
          }}
          isError={true} />
      </>
    );
  }

  console.debug(sessionResult);

  if (!haveResult) {
    return (
      <>
        <h2>Payment Processing...</h2>
      </>
    );
  }

  return (
    <>
      <h2>Payment Finished</h2>
      <ul>
        <li>
          Status: {
            sessionResult.status === "completed"
              ? "Completed"
              : sessionResult.status}
        </li>
        <li>Message: {sessionResult.message}</li>
        <li>
          <Link style={{ color: "blue" }} to="/login">
            Request a sign-in link here
          </Link>
        </li>
      </ul>
    </>
  );
}

function SignUpForm(props) {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const firstNameRef = useRef();
  const [firstName, setFirstName] = useState("");
  const lastNameRef = useRef();
  const [lastName, setLastName] = useState("");
  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const emailValidationRegex = /^\s*(\w+@\w+\.[a-z]+)?\s*$/i;
  const [graduationYear, setGraduationYear] = useState(null);
  const [ageEligible, setAgeEligible] = useState(false);
  const [role, setRole] = useState(null);
  const roles = ["Student", "Parent"];
  const graduationYears = useMemo(() => {
    const now = new Date();
    const nextYear = now.getMonth() > 4;
    const startYear = now.getFullYear() + (nextYear ? 1 : 0);
    return Array.from(Array(4).keys(), x => x + startYear);
  }, []);
  const yearToName = useCallback(year => {
    const keyToName = {
      0: "Senior",
      1: "Junior",
      2: "Sophomore",
      3: "Freshman",
    };
    const yearMap = graduationYears.reduce((cur, val, idx) => {
      cur[val] = `${val} (${keyToName[idx]})`;
      return cur;
    }, {});
    return yearMap[year];
  }, [graduationYears]);
  const yearToCourseId = useCallback(year => {
    const keyToName = {
      0: 12,
      1: 11,
      2: 10,
      3: 9,
    };
    const yearMap = graduationYears.reduce((cur, val, idx) => {
      cur[val] = keyToName[idx];
      return cur;
    }, {});
    return yearMap[year];
  }, [graduationYears]);

  const [formDisabled, setFormDisabled] = useState(false);
  const formIncomplete = (
    !(
      ageEligible &&
      firstName &&
      lastName &&
      email &&
      emailValidationRegex.test(email) &&
      graduationYear &&
      role
    )
  );

  return (
    <>
      <h2>Tell Us About You 🧐</h2>
      <div className="customForm">
        <Checkbox
          label="Are you at least 18 years old?"
          required={true}
          modified={ageEligible}
          disabled={formDisabled}
          prefix={`signUp:over18`}
          fieldName="over18"
          fieldValue={ageEligible}
          setValue={setAgeEligible}
        />
        <EditableTextField
          label="First Name"
          fieldName="userFirstName"
          fieldType="text"
          fieldValue={firstName}
          editable={ageEligible && !formDisabled}
          required={true}
          modified={firstName !== ""}
          useRef={el => {
            if (el !== null && firstNameRef.current === null) {
              el.focus();
              firstNameRef.current = el;
            }
          }}
          useKeyUp={e => {
            if (e.key === "Enter") {
              lastNameRef.current.focus();
            }
          }}
          setValue={setFirstName}
        />
        <EditableTextField
          label="Last Name"
          fieldName="userLastName"
          fieldType="text"
          fieldValue={lastName}
          editable={ageEligible && !formDisabled}
          required={true}
          modified={lastName !== ""}
          useRef={lastNameRef}
          useKeyUp={e => {
            if (e.key === "Enter") {
              emailRef.current.focus();
            }
          }}
          setValue={setLastName}
        />
        <EditableTextField
          label="Email"
          fieldName="userEmail"
          fieldType="text"
          fieldValue={email}
          editable={ageEligible && !formDisabled}
          required={true}
          modified={email !== ""}
          useRef={emailRef}
          validationRegex={emailValidationRegex}
          useKeyUp={e => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
          setValue={setEmail}
        />
        <SingleSelectField
          label="Graduation Year"
          editable={ageEligible && !formDisabled}
          required={true}
          modified={graduationYears.includes(graduationYear)}
          prefix={`signUp:graduationYear`}
          value={graduationYear}
          choices={graduationYears}
          updateValue={setGraduationYear}
          valueTransformer={yearToName}
        />
        <SingleSelectField
          label="You are a ...?"
          editable={ageEligible && !formDisabled}
          required={true}
          modified={roles.includes(role)}
          prefix={`signUp:role`}
          value={role}
          choices={roles}
          updateValue={setRole}
        />
      </div>
      {!ageEligible && (
        <div>
          <h3>Note</h3>
          <p>
            You must be 18 or older in order to complete this form. If under 18,
            you must get a parent/guardian to sign up first, then invite you to
            account.
          </p>
        </div>
      )}
      {ageEligible && role === "Student" && (
        <div>
          <h3>A Note to Students</h3>
          <p>
            If you're signing up for the first time, you're in the right place.
            But if you have a parent that's already signed up, it's better that
            they set up the account and invite you to join theirs.
          </p>
          <p>
            Alternately, you can sign up first, then invite your parents to join
            your account. How you do it is up to you and your family.
          </p>
        </div>
      )}
      <div className="buttonArea">
        <button
          disabled={formDisabled || formIncomplete}
          onClick={e => {
            e.preventDefault();
            setFormDisabled(true);
            createStripeSession({
              firstName: firstName,
              lastName: lastName,
              email: email,
              courseAccessId: yearToCourseId(graduationYear),
              role: role.toLowerCase(),
              graduationYear: graduationYear,
              domain: window.location.origin,
            }).then(result => {
              props.setClientSecret(result.data.clientSecret);
              setFormDisabled(false);
              navigate("/sign-up/payment");
            }).catch(e => {
              console.debug(e);
              if (e instanceof FirebaseError) {
                console.debug(`code: ${e.code}`);
                if (["functions/already-exists"].includes(e.code)) {
                  setErrorText("An account with this email already exists");
                }
              } else {
                setErrorText(`An unknown error occured: ${e}`);
              }
            });
          }}>Next</button>
      </div>
      <Alert
        text={errorText}
        timeout={5000}
        disabled={!errorText}
        onExpired={() => {
          setErrorText("");
          setFormDisabled(false);
        }}
        isError={true} />
    </>
  );
}


export { SignUp };